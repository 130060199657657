import { Col, Row } from 'react-bootstrap';
import { FaEnvelope, FaFacebookMessenger } from 'react-icons/fa';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

function Contact() {
  return (
    <Layout>
      <SEO title="Contact" />
      <Row className="text-center container mx-auto py-5">
        <Col>
          <Row>
            <Col>
              <h1>Contact Us</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>We would like you hear from you!</h5>
            </Col>
          </Row>
          <Row>
            <Col as="p" xs lg="8" className="mx-auto">
              If you have any suggestions, inquiries or business collaboration.
              Please let us know! We will response as soon as we can.
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs lg="8" className="mx-auto">
              <a
                href="https://m.me/WalttyPlayingCardStudio"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light"
              >
                <FaFacebookMessenger className="me-2" />
                Facebook messenge
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs lg="8" className="mx-auto">
              <a href="mailto:walttycontact@gmail.com" className="text-light">
                <FaEnvelope className="me-2" />
                walttycontact@gmail.com
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default Contact;
